<template>
  <main class="container_order pa-5">
    <div class="order">
      <div class="order_table">
        <div class="row header_history no-gutters">
          <div
            :key="item"
            v-for="item in $t('ORDER_HISTORY.order_table')"
            class="col-sm-2 h_name"
          >
            {{ item }}
          </div>
        </div>
        <div
          v-for="(item, index) in orders"
          :key="index"
          class="row body_history no-gutters"
        >
          <div class="col-sm-2 text_u">
            №{{ item.id }}
          </div>
          <div class="col-sm-2 text_grey text_u">
            {{ new Date(item.created_at).toLocaleDateString() }}
          </div>
          <div class="col-sm-2 text_grey">
            {{ item.status.title || '-- статус заказа --' }}
          </div>
          <div class="col-sm-2 text_l">
            {{ item.total_price }}
          </div>
          <div class="col-sm-4 text_l">
            <div class="history_actions">
              <div
                class="icon_c cursor-pointer"
                @click="
                  $router.push({
                    name: 'craftsman-order-description',
                    params: { id: item.id },
                  })
                "
              >
                <span class="order_look">
                  <v-icon size="14">mdi-eye</v-icon>
                </span>
                <span>view</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav class="mb-16" aria-label="Page navigation example">
        <ul class="pagination justify-content-end">
          <li class="page-item disabled">
            <button
                class="btn"
                :class="{'btn_disabled': page === 1}"
                @click="prev"
            >
              Previous
            </button>
          </li>
          <li class="pagination-page">
            <span>{{ page }}</span>
          </li>
          <li class="page-item">
            <button
                class="btn"
                :class="{'btn_disabled': page === countOfPages }"
                @click="next"
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </main>
</template>

<script>
import axios from '@/core/services/api.service'

export default {
  name: 'CraftsmanOrderHistory',
  data() {
    return {
      page: 1,
      totalPage: 0,
      pagesPerPage: 20,
      orders: []
    }
  },
  computed: {
    countOfPages () {
      return Math.ceil(this.totalPage / this.pagesPerPage)
    }
  },
  watch: {
    page () {
      this.getMyOrders()
    }
  },
  methods: {
    prev () {
      if (this.page > 1) {
        this.page -= 1
      }
    },
    next () {
      if (this.page < this.countOfPages) {
        this.page += 1
      }
    },
    getMyOrders () {
      axios.get(`/specialgoods/artisan-order?page=${this.page}&count=${this.pagesPerPage}`)
          .then((res) => {
            this.orders = res.data.data
            this.totalPage = res.data.meta.total
            this.pagesPerPage = res.data.meta.per_page
          })
    },
  },
  mounted() {
    this.getMyOrders()
  },
}
</script>

<style scoped lang="scss">
@import url('https://netdna.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;1,300;1,400;1,500;1,700;1,800&display=swap');

$white: #ffffff;
$grey: #b5b5b5;
$gr: #f5f5f5;
$bl: #423f3d;
$black: #31343c;
$gr_c: #fbfbfb;
$grtx: #a4a4a4;
$green: #cfebcc;
$gr_col: #818181;
$green_c: #a3c2a0;
$bl_c: #90ace2;

i {
  font-family: fontawesome !important;
}
.pagination-page {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}
.btn {
  width: 100%;
  min-width: 100px;
  height: fit-content;
  padding: 10px 0;
  font-size: 12px;
  font-weight: 700;
  color: #666666;
  line-height: 18px;
  border-radius: 2px;
  text-transform: uppercase;
  border: 1px solid #ccc;
  letter-spacing: 0.13em;

  &_disabled {
    background: darkgrey !important;
    &:hover {
      cursor: not-allowed !important;
    }
  }
}

@mixin ftz($fs, $fw, $col) {
  font-size: $fs;
  font-weight: $fw;
  color: $col;
}

%d_flex {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.order {
  background: $white;

  @media (max-width: 840px) {
    overflow: scroll;
  }

  .search-box {
    position: relative;
    margin: 14px 23px;
    overflow: hidden;
    .v-text-field {
      border: 1px solid $gr;
      border-radius: 1px;
      padding: 2px 10px 2px 25px;
      width: 21rem;
      @include ftz(0.875rem, 500, $grey);
    }
  }

  .order_table {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    @media (max-width: 996px) {
      overflow: scroll;
    }

    .header_history {
      @extend %d_flex;
      width: 100%;
      height: 40px;
      background: $gr_c;
      border-radius: 1px;
      margin-right: 1rem;

      @media (max-width: 541px) {
        .col-sm-2 {
          width: 20%;
        }
        .col-sm-4 {
          width: 40%;
        }
      }

      .h_name {
        @include ftz(0.875rem, 500, $bl);
        padding-left: 24px;
      }
    }

    .body_history {
      border-bottom: 1px solid $gr;
      padding: 14px 0;
      @include ftz(0.875rem, 500, $bl);
      & > div {
        padding-left: 24px;
      }

      @media (max-width: 541px) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        width: 100%;

        .col-sm-2 {
          width: 20%;
        }
        .col-sm-4 {
          width: 40%;
        }
      }

      .text_grey {
        color: $grtx;
      }
      .text_l {
        text-transform: lowercase;
      }
      .text_u {
        text-transform: uppercase;
      }

      .history_actions {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 10px;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 541px) {
          overflow: inherit;
        }

        .icon_c {
          position: relative;
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          align-items: center;
          width: 33%;
          margin-right: 1rem;

          &:nth-child(3) {
            margin-right: 0;
          }

          @media (max-width: 1180px) {
            width: 49%;
            @media (max-width: 768px) {
              width: 60%;
            }
          }

          .order_look,
          .order_look_r,
          .order_look_e {
            display: flex;
            justify-content: center;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            background-color: $green;
            border: 2px solid $green;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.search-box {
  .v-input__slot {
    &:before,
    &:after {
      content: none !important;
    }
  }
  .v-icon {
    color: #423f3d;
    font-size: 16px;
  }
}
</style>
